.fc-widget-normal .hotline-launcher.h-open,
.fc-widget-normal.expanded-modal .hotline-launcher {
  width: 330px !important;
}

::ng-deep .hotline-launcher.h-open {
  width: 330px !important;
}

.shop-mat-stroked {
  padding: 0 2rem;
  text-transform: uppercase;
  font-size: 15px;
}
@media (max-width: 575px) {
  .shop-mat-stroked {
    width: 100%;
    justify-content: center;
  }
}

.shop-mat-save {
  transition: all 0.1s ease;
  padding: 0 2rem;
  color: var(--white);
  text-transform: uppercase;
  font-size: 15px;
}
@media (max-width: 575px) {
  .shop-mat-save {
    width: 100%;
    justify-content: center;
  }
}

.shop-mat-primary {
  padding: 0 2rem !important;
  color: var(--white) !important;
  text-transform: uppercase;
}
@media (max-width: 575px) {
  .shop-mat-primary {
    width: 100%;
    justify-content: center;
  }
}

.shop-mat-danger {
  padding: 0 2rem;
  background-color: #f44336;
  color: var(--white);
  text-transform: uppercase;
  font-size: 15px;
}
.shop-mat-danger:hover {
  background-color: #ea1c0d;
}
@media (max-width: 575px) {
  .shop-mat-danger {
    width: 100%;
    justify-content: center;
  }
}

.shop-mat-cancel {
  background-color: var(--white);
  color: var(--black);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  text-transform: uppercase;
  font-size: 15px;
}
.shop-mat-cancel:hover {
  background-color: var(--white);
}
@media (max-width: 575px) {
  .shop-mat-cancel {
    width: 100%;
    justify-content: center;
  }
}