/*=====================
    03.Home slider CSS start
    ==========================*/
.home-slider .layout-7 .home,
.center-slider .layout-7 .home {
    height: 60vh;
}
.home-slider .layout-7 .home .slider-contain,
.center-slider .layout-7 .home .slider-contain {
    height: 60vh;
}
.slider-contain > div {
    h1 {
        font-size: 28px !important;
    }
}
.home-slider .slick-prev,
.home-slider .slick-next,
.center-slider .slick-prev,
.center-slider .slick-next {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    margin-top: -10px;
}
.home-slider .slick-prev:before,
.home-slider .slick-next:before,
.center-slider .slick-prev:before,
.center-slider .slick-next:before {
    opacity: 1;
    color: #b6c0d4;
    border-radius: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.home-slider .slick-next,
.center-slider .slick-next {
    right: 1px;
}
.home-slider .slick-prev:before,
.center-slider .slick-prev:before {
    content: "←";
}
.home-slider .slick-next:before,
.center-slider .slick-next:before {
    content: "→";
}
.home-slider .slick-prev,
.center-slider .slick-prev {
    left: 1px;
    z-index: 1;
}
.home-slider .home,
.center-slider .home {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 75vh;
}
.home-slider .slider-contain,
.center-slider .slider-contain {
    width: 100%;
    height: 75vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.home-slider .slider-contain h1,
.center-slider .slider-contain h1 {
    margin-bottom: 0;
    margin-top: 10px;
}
.home-slider .slider-contain h4,
.center-slider .slider-contain h4 {
    font-weight: 700;
    letter-spacing: 0.4em;
    color: #777777;
    margin-bottom: 0;
    margin-top: 5px;
}
.home-slider .slider-contain .btn-solid,
.home-slider .slider-contain .btn-outline,
.center-slider .slider-contain .btn-solid,
.center-slider .slider-contain .btn-outline {
    margin-top: 20px;
}
.home-slider .p-left .slider-contain,
.center-slider .p-left .slider-contain {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.home-slider .p-right .slider-contain,
.center-slider .p-right .slider-contain {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.home-slider .p-center .slider-contain,
.center-slider .p-center .slider-contain {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.home-slider.text-white .slider-contain h1,
.home-slider.text-white .slider-contain h4,
.center-slider.text-white .slider-contain h1,
.center-slider.text-white .slider-contain h4 {
    color: var(--white);
}
.home-slider:hover .slick-prev,
.home-slider:hover .slick-next,
.center-slider:hover .slick-prev,
.center-slider:hover .slick-next {
    opacity: 1;
    -webkit-transform: scale(2.5);
    transform: scale(2.5);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.home-slider:hover .slick-prev:before,
.home-slider:hover .slick-next:before,
.center-slider:hover .slick-prev:before,
.center-slider:hover .slick-next:before {
    opacity: 1;
}
.home-slider:hover .slick-next,
.center-slider:hover .slick-next {
    right: 100px;
}
.home-slider:hover .slick-prev,
.center-slider:hover .slick-prev {
    left: 100px;
}

.home-slider-container .slider-contain {
    padding: 0 100px;
}

.center-slider {
    border-left: 1px solid #f1f5f4;
    border-right: 1px solid #f1f5f4;
}

.banner-slider .height-banner {
    height: 100%;
}
.banner-slider .home {
    height: 81vh;
}
.banner-slider .home .slider-contain {
    height: 81vh;
}
.banner-slider .home-banner > div img {
    width: 100%;
}
.banner-slider .home-banner > div:last-child img {
    margin-top: 30px;
}

.absolute-banner {
    margin-top: -105px;
}
.absolute-banner .absolute-bg {
    background-color: var(--white);
    position: relative;
    padding: 25px;
    -webkit-box-shadow: 0 0 8px 0 #ddd;
    box-shadow: 0 0 8px 0 #ddd;
}

/*=====================
        1.1.Button CSS start
        ==========================*/
button {
    cursor: pointer;
}
button.btn.btn-solid:active,
button.btn.btn-outline:active {
    background-image: linear-gradient(30deg, #ff4c3b 50%, transparent 50%);
    color: var(--white);
    background: #ff4c3b;
}

.btn-primary:active {
    color: #aaa !important;
    background-color: var(--white) !important;
    border-color: var(--white) !important;
}

.btn {
    line-height: 20px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    border-radius: 0;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}
.btn:hover {
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}
.btn:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn-solid {
    padding: 13px 29px;
    color: var(--white) !important;
    letter-spacing: 0.05em;
    border: 2px solid #ff4c3b;
    background-image: linear-gradient(30deg, #ff4c3b 50%, transparent 50%);
    background-size: 540px;
    background-repeat: no-repeat;
    background-position: 0;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}
.btn-solid:hover {
    background-position: 100%;
    color: var(--black) !important;
    background-color: var(--white);
}
.btn-solid.black-btn {
    background-image: linear-gradient(30deg, #222222 50%, transparent 50%);
    border: 2px solid #222222;
}
.btn-solid:focus {
    color: var(--black) !important;
}

.btn-outline {
    display: inline-block;
    padding: 13px 29px;
    letter-spacing: 0.05em;
    border: 2px solid #ff4c3b;
    position: relative;
    color: var(--black);
}
.btn-outline:before {
    -webkit-transition: 0.5s all ease;
    transition: 0.5s all ease;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
    opacity: 0;
    content: "";
    background-color: #ff4c3b;
    z-index: -2;
}
.btn-outline:hover {
    color: var(--white);
}
.btn-outline:hover:before {
    -webkit-transition: 0.5s all ease;
    transition: 0.5s all ease;
    left: 0;
    right: 0;
    opacity: 1;
}
.btn-outline:focus:before {
    -webkit-transition: 0.5s all ease;
    transition: 0.5s all ease;
    left: 0;
    right: 0;
    opacity: 1;
}

button.btn.btn-solid:active,
button.btn.btn-outline:active {
    background-image: linear-gradient(30deg, #ff4c3b 50%, transparent 50%);
    color: var(--white);
    background: #ff4c3b;
}

.btn-classic:hover {
    background-color: #ff4c3b;
}

.btn-theme {
    background-color: #ff4c3b;
    color: var(--white);
}

.center-slider:hover .slick-prev {
    left: 20px;
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
}

::ng-deep .home-slider:hover .slick-next {
    right: 80px;
}
::ng-deep .home-slider:hover .slick-prev {
    left: 80px;
}
