/**
* Styles files (such as product-slider-two.component.scss) for dynamic imported components do NOT get loaded at all. Until we figure this out, 
* place everything into related to dynamic imported components here and leter try to move each part of this file into appropriate component style file.
*/

h6 {
    font-size: 14px;
    font-weight: 400;
    color: #777777;
    line-height: 24px;
}

h6 {
    line-height: 1;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 5px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    font-size: 16px;
    margin-top: -5px !important;
}

.product-slick.product-zoom.slick-initialized.slick-slider {
    z-index: 1;
}

.layout7-product .product-box {
    padding: 2px;
}
.layout7-product .product-box img {
    width: 100%;
}
.layout7-product .product-box .cart-info {
    right: 7px;
    bottom: 7px;
}
.layout7-product .product-box .details-product {
    position: absolute;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    opacity: 0;
    bottom: 15px;
    left: 15px;
}
.layout7-product .product-box .details-product h6,
.layout7-product .product-box .details-product h4 {
    margin-bottom: 0;
}
.layout7-product .product-box .details-product h6 {
    color: var(--black);
}
.layout7-product .product-box:hover .details-product {
    opacity: 1;
    -webkit-animation: fadeIn 500ms ease-in-out;
    animation: fadeIn 500ms ease-in-out;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.img-zoom-result {
    background-size: 1614px 2064px !important;
}

.offer-slider .slick-slide {
    line-height: 1;
}

.slick-slide {
    padding: 0.5rem;
}

.slick-next-custom,
.slick-prev-custom {
    position: absolute;
    display: block;
    cursor: pointer;
    top: 50%;
    transform: translate(0, -50%);
}

.slick-next-custom {
    right: -25px;
}

.slick-prev-custom {
    left: -25px;
}
//

.center-slider .product-box {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.center-slider .product-box .img-wrapper img {
    padding: 15px;
}
.center-slider .slick-next:before,
.center-slider .slick-prev:before {
    -webkit-box-shadow: 0 0 8px 0 #dddddd;
    box-shadow: 0 0 8px 0 #dddddd;
}
.center-slider .title2 .title-inner2 {
    margin-bottom: 0;
}
.center-slider:hover .product-box {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.center-slider:hover .slick-next {
    right: 20px;
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
}
.center-slider:hover .slick-prev {
    left: 20px;
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
}

.theme-card {
    position: relative;
}
.theme-card.center-align {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.theme-card.card-border {
    border: 1px solid #dddddd;
}
.theme-card.card-border h5 {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 50px;
}
.theme-card.card-border .slick-prev,
.theme-card.card-border .slick-next {
    top: -45px;
}
.theme-card.card-border .slick-next {
    right: 30px;
}
.theme-card.card-border .slick-prev {
    right: 50px;
}
.theme-card.card-border .offer-slider {
    padding-bottom: 20px;
}
.theme-card.card-border .offer-slider img {
    padding: 15px 15px 15px 30px;
}
.theme-card.card-border .offer-slider .media .media-body a h6 {
    margin-right: 25px;
}
.theme-card h5 {
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 10px;
}
.theme-card h5.title-border {
    border-bottom: 1px solid #dddddd;
}
.theme-card .slick-prev,
.theme-card .slick-next {
    top: -35px;
}
.theme-card .slick-prev:before,
.theme-card .slick-next:before {
    color: #222222;
    opacity: 0.6;
}
.theme-card .slick-next {
    right: 0;
}
.theme-card .slick-prev {
    left: unset;
    right: 25px;
}
.theme-card .offer-slider img {
    height: 160px !important;
    padding: 15px 15px 15px 0;
}
.theme-card .offer-slider > div .media:last-child .media-body {
    margin-top: 15px;
}
.theme-card .offer-slider > div .media:last-child img {
    margin-bottom: -15px;
}
.theme-card .offer-slider .media .media-body .rating i {
    padding-right: 5px;
}
.theme-card .offer-slider .media .media-body .rating i:nth-child(-n + 4) {
    color: #ffa200;
}
.theme-card .offer-slider .media .media-body .rating i:last-child {
    color: #dddddd;
}
.theme-card .offer-slider .media .media-body a h6 {
    margin-right: 61px;
    line-height: 17px;
    margin-top: -10px;
    margin-bottom: 0;
}
.theme-card .offer-slider .media .media-body h4 {
    margin-top: 10px;
    font-weight: 700;
    color: #222222;
    margin-bottom: 0;
}

.full-box .theme-card .offer-slider .product-box2 .media img {
    height: 100%;
    max-width: 250px !important;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.full-box .theme-card .offer-slider .product-box2 .media .media-body a h6 {
    margin-right: 20px;
}
.full-box .theme-card .offer-slider .product-box2 .media .media-body h4 {
    font-size: 20px;
    margin-top: 5px;
}
.full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant li {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    margin-right: 5px;
    margin-top: 15px;
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: middle;
}
.full-box .theme-card .offer-slider .product-box2 + .product-box2 {
    border-top: 1px solid #f1f5f4;
}
.full-box .theme-card .offer-slider .product-box2:hover .media img {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

/*=====================
        09.Product Box CSS start
        ==========================*/
.br[_ngcontent-c25] {
    margin-top: 5px !important;
}

.br[_ngcontent-c16] {
    margin-top: 5px !important;
}

.br[_ngcontent-c17] {
    margin-top: 5px !important;
}

.search-product .product-box .product-detail,
.search-product .product-box .product-info,
.search-product .product-wrap .product-detail,
.search-product .product-wrap .product-info {
    padding-left: 0;
}

.color-variant li {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
    vertical-align: middle;
}

.size-variant {
    padding-top: 10px;
}
.size-variant li {
    display: inline-block;
    width: 24px;
    height: 24px;
    font-size: 14px;
    text-align: center;
    color: #777777;
    border: 1px solid;
    line-height: 20px;
    margin-right: 5px;
    text-transform: lowercase;
    cursor: pointer;
}
.size-variant li.square {
    border-radius: 0;
}
.size-variant li.round {
    border-radius: 100%;
}

.no-slider .product-box {
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: calc(25% - 30px);
    margin: 0 15px 30px;
}
.no-slider .product-box:nth-last-child(-n + 4) {
    margin: 0 15px 0;
}

.product-para p {
    margin-bottom: 0;
    padding-bottom: 30px;
    line-height: 24px;
    letter-spacing: 0.05em;
}

.product-box .product-detail h4 del,
.product-box .product-info h4 del,
.product-wrap .product-detail h4 del,
.product-wrap .product-info h4 del {
    color: #aaa;
    font-size: 14px;
}

.product-box .img-wrapper .product-thumb-list li img,
.product-wrap .img-wrapper .product-thumb-list li img {
    width: 45px;
}

.product-box,
.product-wrap {
    position: relative;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.product-box .img-block,
.product-wrap .img-block {
    background-color: #f9f9f9;
    position: relative;
    overflow: hidden;
}
.product-box .img-block .front,
.product-wrap .img-block .front {
    opacity: 1;
    top: 0;
    left: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.product-box .img-block .back,
.product-wrap .img-block .back {
    opacity: 0;
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    top: 0;
    left: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
}
.product-box .img-block .lable-wrapper,
.product-wrap .img-block .lable-wrapper {
    margin: 0 auto;
    top: 40px;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    z-index: 2;
}
.product-box .img-block .lable-wrapper .lable1,
.product-box .img-block .lable-wrapper .lable2,
.product-wrap .img-block .lable-wrapper .lable1,
.product-wrap .img-block .lable-wrapper .lable2 {
    font-size: 14px;
    padding: 10px 14px 10px 20px;
    display: inline-block;
    text-transform: uppercase;
    text-align: center;
}
.product-box .img-block .lable-wrapper .lable1,
.product-wrap .img-block .lable-wrapper .lable1 {
    background-color: #ff4c3b;
    color: var(--white);
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
}
.product-box .img-block .lable-wrapper .lable2,
.product-wrap .img-block .lable-wrapper .lable2 {
    background-color: var(--white);
    color: var(--black);
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
}
.product-box .img-wrapper,
.product-wrap .img-wrapper {
    position: relative;
    overflow: hidden;
}
.product-box .img-wrapper .front,
.product-wrap .img-wrapper .front {
    opacity: 1;
    top: 0;
    left: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.product-box .img-wrapper .back,
.product-wrap .img-wrapper .back {
    opacity: 0;
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    top: 0;
    left: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
}
.product-box .img-wrapper .cart-box,
.product-wrap .img-wrapper .cart-box {
    position: absolute;
    margin: 0 auto;
    display: inline-block;
    right: 0;
    left: 0;
    border-radius: 50px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    padding: 12px 15px;
    -webkit-box-shadow: 0 0 12px 0 #dddddd;
    box-shadow: 0 0 12px 0 #dddddd;
    bottom: 30px;
    background-color: var(--white);
    opacity: 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.product-box .img-wrapper .cart-box button,
.product-wrap .img-wrapper .cart-box button {
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    padding: 0;
}
.product-box .img-wrapper .cart-box i,
.product-wrap .img-wrapper .cart-box i {
    color: #6f6f6f;
    font-size: 18px;
    padding-left: 8px;
    padding-right: 8px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    display: inline-block;
}
.product-box .img-wrapper .cart-box i:hover,
.product-wrap .img-wrapper .cart-box i:hover {
    color: #ff4c3b;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.product-box .img-wrapper .lable-block .lable3,
.product-wrap .img-wrapper .lable-block .lable3 {
    border-radius: 100%;
    text-align: center;
    font-size: 13px;
    font-weight: 700;
    position: absolute;
    padding: 9px 4px;
    text-transform: uppercase;
    top: 0;
    right: 0;
    z-index: 9;
}
.product-box .img-wrapper .lable-block .lable4,
.product-wrap .img-wrapper .lable-block .lable4 {
    position: absolute;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    top: 7px;
    right: 7px;
    letter-spacing: 0.1em;
    z-index: 1;
}
.product-box .cart-info,
.product-box .cart-wrap,
.product-wrap .cart-info,
.product-wrap .cart-wrap {
    position: absolute;
    bottom: 40px;
    text-align: center;
    margin: 0 auto;
    display: inline-block;
    right: 0;
    left: 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.product-box .cart-info a [class^="icon-"],
.product-box .cart-info a [class*=" icon-"],
.product-box .cart-wrap a [class^="icon-"],
.product-box .cart-wrap a [class*=" icon-"],
.product-wrap .cart-info a [class^="icon-"],
.product-wrap .cart-info a [class*=" icon-"],
.product-wrap .cart-wrap a [class^="icon-"],
.product-wrap .cart-wrap a [class*=" icon-"] {
    display: inline-block;
}
.product-box .cart-info.cart-wrap,
.product-box .cart-wrap.cart-wrap,
.product-wrap .cart-info.cart-wrap,
.product-wrap .cart-wrap.cart-wrap {
    bottom: 0;
    text-align: right;
    left: unset;
}
.product-box .cart-info.cart-wrap i,
.product-box .cart-wrap.cart-wrap i,
.product-wrap .cart-info.cart-wrap i,
.product-wrap .cart-wrap.cart-wrap i {
    display: block;
    padding-bottom: 10px;
    padding-top: 10px;
}
.product-box .cart-info.cart-wrap.cart-effect-left,
.product-box .cart-wrap.cart-wrap.cart-effect-left,
.product-wrap .cart-info.cart-wrap.cart-effect-left,
.product-wrap .cart-wrap.cart-wrap.cart-effect-left {
    left: 0;
    right: unset;
}
.product-box .cart-info button,
.product-box .cart-wrap button,
.product-wrap .cart-info button,
.product-wrap .cart-wrap button {
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    padding: 0;
}
.product-box .cart-info i,
.product-box .cart-wrap i,
.product-wrap .cart-info i,
.product-wrap .cart-wrap i {
    color: #6f6f6f;
    font-size: 18px;
    padding-right: 10px;
    padding-left: 10px;
}
.product-box .cart-info i:hover,
.product-box .cart-wrap i:hover,
.product-wrap .cart-info i:hover,
.product-wrap .cart-wrap i:hover {
    color: #ff4c3b;
}
.product-box .cart-detail,
.product-wrap .cart-detail {
    position: absolute;
    top: 15px;
    right: 20px;
    opacity: 0;
}
.product-box .cart-detail i,
.product-wrap .cart-detail i {
    color: #6f6f6f;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 8px;
    padding-bottom: 8px;
}
.product-box .cart-detail button,
.product-wrap .cart-detail button {
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    padding: 0;
}
.product-box .product-detail,
.product-box .product-info,
.product-wrap .product-detail,
.product-wrap .product-info {
    padding-left: 10px;
}
.product-box .product-detail .rating,
.product-box .product-info .rating,
.product-wrap .product-detail .rating,
.product-wrap .product-info .rating {
    margin-top: 15px;
}
.product-box .product-detail .rating .pro-rating .br-units,
.product-box .product-info .rating .pro-rating .br-units,
.product-wrap .product-detail .rating .pro-rating .br-units,
.product-wrap .product-info .rating .pro-rating .br-units {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.product-box .product-detail .rating i,
.product-box .product-info .rating i,
.product-wrap .product-detail .rating i,
.product-wrap .product-info .rating i {
    padding-right: 5px;
}
.product-box .product-detail .rating i:nth-child(-n + 4),
.product-box .product-info .rating i:nth-child(-n + 4),
.product-wrap .product-detail .rating i:nth-child(-n + 4),
.product-wrap .product-info .rating i:nth-child(-n + 4) {
    color: #ffa200;
}
.product-box .product-detail .rating i:last-child,
.product-box .product-info .rating i:last-child,
.product-wrap .product-detail .rating i:last-child,
.product-wrap .product-info .rating i:last-child {
    color: #dddddd;
}
.product-box .product-detail h6,
.product-box .product-info h6,
.product-wrap .product-detail h6,
.product-wrap .product-info h6 {
    line-height: 1;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 5px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    font-size: 16px;
    margin-top: -17px;
}
.product-box .product-detail h4,
.product-box .product-info h4,
.product-wrap .product-detail h4,
.product-wrap .product-info h4 {
    font-size: 18px;
    color: #222222;
    font-weight: 700;
    margin-bottom: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.product-box .product-detail .color-variant,
.product-box .product-info .color-variant,
.product-wrap .product-detail .color-variant,
.product-wrap .product-info .color-variant {
    padding-top: 12px;
}
.product-box .product-detail .color-variant li,
.product-box .product-info .color-variant li,
.product-wrap .product-detail .color-variant li,
.product-wrap .product-info .color-variant li {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    margin-right: 5px;
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
    cursor: pointer;
    vertical-align: middle;
}
.product-box .product-detail .color-variant li.white,
.product-box .product-info .color-variant li.white,
.product-wrap .product-detail .color-variant li.white,
.product-wrap .product-info .color-variant li.white {
    background-color: var(--white);
    border: 1px solid #aaa;
}
.product-box .product-detail .color-variant li.pink,
.product-box .product-info .color-variant li.pink,
.product-wrap .product-detail .color-variant li.pink,
.product-wrap .product-info .color-variant li.pink {
    background-color: #ffc0cb;
}
.product-box .product-detail .color-variant li.red,
.product-box .product-info .color-variant li.red,
.product-wrap .product-detail .color-variant li.red,
.product-wrap .product-info .color-variant li.red {
    background-color: #ff647f;
}
.product-box .product-detail .color-variant li.green,
.product-box .product-info .color-variant li.green,
.product-wrap .product-detail .color-variant li.green,
.product-wrap .product-info .color-variant li.green {
    background-color: #6fb866;
}
.product-box .product-detail .color-variant li.yellow,
.product-box .product-info .color-variant li.yellow,
.product-wrap .product-detail .color-variant li.yellow,
.product-wrap .product-info .color-variant li.yellow {
    background-color: #f2f896;
}
.product-box .product-detail .color-variant li.skyblue,
.product-box .product-info .color-variant li.skyblue,
.product-wrap .product-detail .color-variant li.skyblue,
.product-wrap .product-info .color-variant li.skyblue {
    background-color: #7ee7ec;
}
.product-box .product-detail .color-variant li.blue,
.product-box .product-info .color-variant li.blue,
.product-wrap .product-detail .color-variant li.blue,
.product-wrap .product-info .color-variant li.blue {
    background-color: #63b4f2;
}
.product-box .product-detail .color-variant li.orange,
.product-box .product-info .color-variant li.orange,
.product-wrap .product-detail .color-variant li.orange,
.product-wrap .product-info .color-variant li.orange {
    background-color: #ffb17a;
}
.product-box .product-detail .color-variant li.purple,
.product-box .product-info .color-variant li.purple,
.product-wrap .product-detail .color-variant li.purple,
.product-wrap .product-info .color-variant li.purple {
    background-color: #eeb1d7;
}
.product-box .product-detail .color-variant li.violet,
.product-box .product-info .color-variant li.violet,
.product-wrap .product-detail .color-variant li.violet,
.product-wrap .product-info .color-variant li.violet {
    background-color: #a06da0;
}
.product-box .product-detail .color-variant li.brown,
.product-box .product-info .color-variant li.brown,
.product-wrap .product-detail .color-variant li.brown,
.product-wrap .product-info .color-variant li.brown {
    background-color: #a57372;
}
.product-box .product-detail .color-variant li.lightgreen,
.product-box .product-info .color-variant li.lightgreen,
.product-wrap .product-detail .color-variant li.lightgreen,
.product-wrap .product-info .color-variant li.lightgreen {
    background-color: #90ee90;
}
.product-box .product-detail .color-variant li.olive,
.product-box .product-info .color-variant li.olive,
.product-wrap .product-detail .color-variant li.olive,
.product-wrap .product-info .color-variant li.olive {
    background-color: #808000;
}
.product-box .product-detail .color-variant li.navy,
.product-box .product-info .color-variant li.navy,
.product-wrap .product-detail .color-variant li.navy,
.product-wrap .product-info .color-variant li.navy {
    background-color: #505c80;
}
.product-box .product-detail .color-variant li.black,
.product-box .product-info .color-variant li.black,
.product-wrap .product-detail .color-variant li.black,
.product-wrap .product-info .color-variant li.black {
    background-color: var(--black);
}
.product-box .product-detail .color-variant li.gray,
.product-box .product-detail .color-variant li .grey,
.product-box .product-info .color-variant li.gray,
.product-box .product-info .color-variant li .grey,
.product-wrap .product-detail .color-variant li.gray,
.product-wrap .product-detail .color-variant li .grey,
.product-wrap .product-info .color-variant li.gray,
.product-wrap .product-info .color-variant li .grey {
    background-color: #777777;
}
.product-box .product-info,
.product-wrap .product-info {
    padding: 0;
    text-align: center;
    position: relative;
}
.product-box .product-info .add-btn,
.product-wrap .product-info .add-btn {
    position: absolute;
    bottom: 110px;
    margin: 0 auto;
    left: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    z-index: 1;
}
.product-box .product-info .add-btn i,
.product-wrap .product-info .add-btn i {
    color: #ff4c3b;
}
.product-box .product-info .add-btn .btn-outline,
.product-wrap .product-info .add-btn .btn-outline {
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    color: #ff4c3b;
}
.product-box .product-info .add-btn .btn-outline:hover,
.product-wrap .product-info .add-btn .btn-outline:hover {
    color: var(--white);
}
.product-box .product-info .add-btn .btn-outline:hover i,
.product-wrap .product-info .add-btn .btn-outline:hover i {
    color: var(--white);
}
.product-box.effect-center .front img,
.product-wrap.effect-center .front img {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.product-box.effect-center .img-wrapper .cart-box,
.product-wrap.effect-center .img-wrapper .cart-box {
    bottom: 20%;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.product-box:hover.effect-center .front img,
.product-wrap:hover.effect-center .front img {
    opacity: 0.3;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.product-box:hover.effect-center .img-wrapper .cart-box,
.product-wrap:hover.effect-center .img-wrapper .cart-box {
    bottom: 35%;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.product-box:hover .img-block .first,
.product-box:hover .img-wrapper .first,
.product-wrap:hover .img-block .first,
.product-wrap:hover .img-wrapper .first {
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.product-box:hover .img-block .back,
.product-box:hover .img-wrapper .back,
.product-wrap:hover .img-block .back,
.product-wrap:hover .img-wrapper .back {
    opacity: 1;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-transform: translateX(0);
    transform: translateX(0);
}
.product-box:hover .cart-info,
.product-wrap:hover .cart-info {
    opacity: 1;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.product-box:hover .cart-info button,
.product-wrap:hover .cart-info button {
    -webkit-animation: fadeInUp 300ms ease-in-out;
    animation: fadeInUp 300ms ease-in-out;
}
.product-box:hover .cart-info a:nth-child(2) i,
.product-wrap:hover .cart-info a:nth-child(2) i {
    -webkit-animation: fadeInUp 500ms ease-in-out;
    animation: fadeInUp 500ms ease-in-out;
}
.product-box:hover .cart-info a:nth-child(3) i,
.product-wrap:hover .cart-info a:nth-child(3) i {
    -webkit-animation: fadeInUp 700ms ease-in-out;
    animation: fadeInUp 700ms ease-in-out;
}
.product-box:hover .cart-info a:nth-child(4) i,
.product-wrap:hover .cart-info a:nth-child(4) i {
    -webkit-animation: fadeInUp 1000ms ease-in-out;
    animation: fadeInUp 1000ms ease-in-out;
}
.product-box:hover .cart-wrap button,
.product-wrap:hover .cart-wrap button {
    -webkit-animation: fadeInRight 300ms ease-in-out;
    animation: fadeInRight 300ms ease-in-out;
}
.product-box:hover .cart-wrap a:nth-child(2) i,
.product-wrap:hover .cart-wrap a:nth-child(2) i {
    -webkit-animation: fadeInRight 500ms ease-in-out;
    animation: fadeInRight 500ms ease-in-out;
}
.product-box:hover .cart-wrap a:nth-child(3) i,
.product-wrap:hover .cart-wrap a:nth-child(3) i {
    -webkit-animation: fadeInRight 700ms ease-in-out;
    animation: fadeInRight 700ms ease-in-out;
}
.product-box:hover .cart-wrap a:nth-child(4) i,
.product-wrap:hover .cart-wrap a:nth-child(4) i {
    -webkit-animation: fadeInRight 1000ms ease-in-out;
    animation: fadeInRight 1000ms ease-in-out;
}
.product-box:hover .cart-wrap.cart-effect-left button,
.product-wrap:hover .cart-wrap.cart-effect-left button {
    -webkit-animation: fadeInLeft 300ms ease-in-out;
    animation: fadeInLeft 300ms ease-in-out;
}
.product-box:hover .cart-wrap.cart-effect-left a:nth-child(2) i,
.product-wrap:hover .cart-wrap.cart-effect-left a:nth-child(2) i {
    -webkit-animation: fadeInLeft 500ms ease-in-out;
    animation: fadeInLeft 500ms ease-in-out;
}
.product-box:hover .cart-wrap.cart-effect-left a:nth-child(3) i,
.product-wrap:hover .cart-wrap.cart-effect-left a:nth-child(3) i {
    -webkit-animation: fadeInLeft 700ms ease-in-out;
    animation: fadeInLeft 700ms ease-in-out;
}
.product-box:hover .cart-wrap.cart-effect-left a:nth-child(4) i,
.product-wrap:hover .cart-wrap.cart-effect-left a:nth-child(4) i {
    -webkit-animation: fadeInLeft 1000ms ease-in-out;
    animation: fadeInLeft 1000ms ease-in-out;
}
.product-box:hover .cart-detail,
.product-wrap:hover .cart-detail {
    opacity: 1;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.product-box:hover .cart-detail button,
.product-wrap:hover .cart-detail button {
    -webkit-animation: fadeInRight 300ms ease-in-out;
    animation: fadeInRight 300ms ease-in-out;
}
.product-box:hover .cart-detail a:nth-child(2) i,
.product-wrap:hover .cart-detail a:nth-child(2) i {
    -webkit-animation: fadeInRight 500ms ease-in-out;
    animation: fadeInRight 500ms ease-in-out;
}
.product-box:hover .cart-detail a:nth-child(3) i,
.product-wrap:hover .cart-detail a:nth-child(3) i {
    -webkit-animation: fadeInRight 700ms ease-in-out;
    animation: fadeInRight 700ms ease-in-out;
}
.product-box:hover .cart-detail a:nth-child(4) i,
.product-wrap:hover .cart-detail a:nth-child(4) i {
    -webkit-animation: fadeInRight 1000ms ease-in-out;
    animation: fadeInRight 1000ms ease-in-out;
}
.product-box:hover .product-info .add-btn,
.product-wrap:hover .product-info .add-btn {
    opacity: 1;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-animation: fadeInUp 500ms ease-in-out;
    animation: fadeInUp 500ms ease-in-out;
}
.product-box:hover .img-wrapper .cart-box,
.product-wrap:hover .img-wrapper .cart-box {
    opacity: 1;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-animation: fadeInUp 400ms ease-in-out;
    animation: fadeInUp 400ms ease-in-out;
}
